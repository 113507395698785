import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Stay Legally Compliant' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Small business compliance?"
           subheader='Start and Manage your business with ease'
        />

        <Section>
          <Card variant='paper'>
          <Text variant='p'>
          We offer:
            </Text>
            <Text variant='p'>
            - Business Tax Fillings
            </Text>
            <Text variant='p'>
            - Company Formation
            </Text>
            <Text variant='p'>
            - Regulatory alerts
            </Text>
      
            </Card>
        </Section>

        <Section>
          <Card variant='paper'>

            <Text variant='p'>
            - Company Formation with Expedited processing
            </Text>
            <Text variant='p'>
            - Annual State filings
            </Text>
            <Text variant='p'>
            - IRS Tax filings
            </Text>
            <Text variant='p'>
            - Regulatory reminders
            </Text>
      
          </Card>
        </Section>



        <Divider />
        {/* <ContactForm /> */}
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
